.project-main{
  background-color: rgb(30,31,33);
}
.slider-container{
  padding: 20px;
  
}
.slider-container img{
  width: 27vw;
}

/* featured proj  */

.featured-proj-img{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;
  gap: 30px;
}
.featured-proj-img img{
  width: 27vw;

}
/* img hover  */
.featured-proj-img img:hover{
  

}
.featured-proj-img h4{
  color: white;
  margin: 15px 0;

}
.overlay h5{
  color: white;
}

/* All Projects  */
/* .main-allProjects{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-wrap: nowrap;
  
} */

.allProjectsHeadings{
  color: white;
  /* margin: 40px 9%; */
  margin-right: 10px;
  /* border-bottom: 1px solid white; */
  width: 30vw;
  text-wrap: nowrap;
  font-size: 30px;
  font-weight: 500;
}




/* Media Queries  */
@media(max-width: 830px){
  .featured-proj-img{
    flex-direction: column;
    align-items: center;
  }
  .featured-proj-img img{
    width: 80vw;
  }
  .featured-proj-img h4{
    font-size: 18px;
  }
  .main-allProjects{
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 50px;
    width: 90vw;
    text-wrap: nowrap;
    gap: 40px;
  }
  .allProjectsHeadings{
    font-size: 18px;
    font-weight: 300;
    text-align: center;
  }
}
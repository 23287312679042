.scrollOnTopButton{
    position: fixed;
    bottom: 15.5px;
    right: 76px;
    z-index: 5;
    transition: all ease-in-out;
    cursor: pointer;
}


/* button css  */
.scrollbutton {
    width: 51.5px;
    height: 51.5px;
    border-radius: 50%;
    background-color: rgb(20, 20, 20);
    border: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 0px 4px rgba(50, 50, 50, 0.253);
    cursor: pointer;
    transition-duration: 0.3s;
    overflow: hidden;
    position: relative;
  }
  
  .svgIcon {
    width: 12px;
    transition-duration: 0.3s;
  }
  
  .svgIcon path {
    fill: white;
  }
  
  .scrollbutton:hover {
    width: 140px;
    border-radius: 50px;
    transition-duration: 0.3s;
    background-color: #000000;
    align-items: center;
  }
  
  .scrollbutton:hover .svgIcon {
    transition-duration: 0.3s;
    transform: translateY(-220%);
  }
  
  .scrollbutton::before {
    position: absolute;
    bottom: -20px;
    content: "Back to Top";
    color: white;
    font-size: 0px;
  }
  
  .scrollbutton:hover::before {
    font-size: 13px;
    opacity: 1;
    bottom: unset;
    transition-duration: 0.3s;
  }
  
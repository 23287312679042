.error-body{
  margin: 0;
  padding: 0;
  font-family: "montserrat",sans-serif;
  min-height: 100vh;
  background-image: linear-gradient(125deg,whitesmoke, #434343);
}

.error-container{
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  color: #343434
  
  
}

.error-container h1{
  font-size: 160px;
  margin: 0;
  font-weight: 900;
  letter-spacing: 20px;
  background: url(/public/image/error-bg.jpg) center no-repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.error-container a{
  text-decoration: none;
  background: whitesmoke;
  color: #1e1f21;
  padding: 12px 24px;
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.4s;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.error-container a:hover{
  background: #1e1f21;
  color: whitesmoke
  ;
}


.serv-main{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "DM Sans", sans-serif;
}
.serv-body{
    background: #1e1f21;
    color: #fff;
    
}
.serv-h2-body{
    display: flex;
    max-width: 100vw;
    justify-content: center;
    align-items: center;
    
}
.serv-body h2{
    
    padding-bottom: 30px;
    position: relative;
    /* font-size: 40px; */
    color: #1e1f21;
   -webkit-text-stroke: 0.1vw whitesmoke;
    text-transform: uppercase;
}
.serv-body h2 span{
    color: whitesmoke;
    
}
.serv-body h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: whitesmoke;
    -webkit-text-stroke: 0vw #242527;
    border-right: 2px solid whitesmoke;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate{
    0%,10%,100%
    {
        width: 0;
    }
    70%,90%
    {
        width: 100%;
    }
}

.box-area{
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
}
.box{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);


}
.box img {
    width: 100%;
    max-height: 90vh;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    transition: transform 0.5s;

}
.overlay{
    height: 0;
    width: 100%;
    background: linear-gradient(transparent, #1c1c1c 58%);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 80px;
    text-align: center;
    font-size: 14px;
    transition: height 0.5s;

}
.overlay h3{
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 80%;
    font-family: "DM Sans", sans-serif;
    font-size: 30px;
    letter-spacing: 2px;
    color: #fff;

}
.overlay p{
    color: #ffffff;
    opacity: 100%;
}

.box:hover img{
    transform: scale(1.2);


}
.box:hover .overlay{
    height: 100%;
}

.box-m > h3{
    text-align: center;
    background-color: rgb(19, 19, 19);
    border-radius: 7px;
}
.text-p{
    font-size: 1.5vw;
    font-weight: 200;
    padding-top: 80px;
}


@media(max-width: 830px){
    .serv-h2-body{
        padding-top: 0px;
    }
    .wrapper{
        padding: 0;
    } 
    .box-area{
        flex-direction: column;
        margin-top: 0;
    }
    .box img{
        max-height: 50vw;
    } 
   
    .overlay h3{
        display: none;
        
    }
    .serv-body h2{
        /* margin-top: 10px; */
        font-size: 25px ;
    }
    .text-p{
        font-size: 2vw;
        font-weight: 300;
        padding-top: 30px;
    }
    .serv-body h2{
        padding-bottom: 15px;
    }
}
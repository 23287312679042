.menu li a.active{
    font-weight: 900;
    color: #000000;
    font-size: 14px;

}


.full-navbar{
    position: fixed;
    z-index: 5;
    width: 100vw;
    top: 0;
    left: 0;

        
    box-sizing: border-box;
    font-size: 13px;
    /* font-size: small; */
    font-weight: 600;
    --color1: #000000 ;
    --color2: #ffffff ;

}

.nav-bar {
    width: 100%;
    display: flex;
    /* padding: 0 10%; */
    padding: 15px 0;
    align-items: center;
    list-style: none;
    position: relative;
    background-color: var(--color2);
    
}
.logo img {width: 40px;
    margin: 0px 50px 0px 100px;
}
.menu {display: flex;
    white-space: nowrap;
}
.menu li {padding-left: 30px;}
.menu li a {
    display: inline-block;
    text-decoration: none;
    color: var(--color1);
    text-align: center;
    transition: 0.15s ease-in-out;
    position: relative;
    text-transform: uppercase;
    
}
.menu li a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--color1);
    transition: 0.15s ease-in-out;
}
.menu li a:hover:after {width: 100%;}
.open-menu , .close-menu {
    position: absolute;
    color: var(--color1);
    cursor: pointer;
    font-size: 1.5rem;
    display: none;
}
.open-menu {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
.close-menu {
    top: 20px;
    right: 20px;
}
#check {display: none;} 


@media(max-width: 830px){
    .menu li a.active{
        background-color: black;
        color: white;
        width: 100vw;
        
    }
    .full-navbar{
        position: absolute;
    }
    .logo img {
        margin: 0 30px;
        width: 30px;
    }
    
    .menu {
        
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 100;
        background-color: var(--color2);
        transition: all 0.2s ease-in-out;
    }
    .menu li {
        margin-top: 35px;
    }
    .menu li a {
        padding: 7px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .open-menu , .close-menu {display: block;}
    #check:checked ~ .menu {right: 0;}
}
.archi-body{
    background: #ffffff;
    color: #1e1f21;

}
.archi-h2-body{
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.archi-body h2{
    
    position: relative;
    font-size: 4vw;
    color: whitesmoke;
   -webkit-text-stroke: 0.2vw #1e1f21;
    text-transform: uppercase;
}

.archi-body h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: #242527;
    -webkit-text-stroke: 0vw whitesmoke;
    border-right: 2px solid #242527;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate{
    0%,10%,100%
    {
        width: 0;
    }
    70%,90%
    {
        width: 100%;
    }
}

@media screen and (max-width: 710px) {
    .archi-body h2{
        font-size: 6vw;
        padding-top: 0;
    }
    .Archi-h{
        font-size: 6vw;
    }
    .Archi-areas-h{
        font-size: 4vw;
        padding-top: 10px;
    }
    .Archi-areas-cat h5{
        font-size: 4vw;
        
    }

  }
.work-main{
width: 100%;
height: 45vh;
background-image: url(/public/image/work.webp);
background-position: center;
background-size: cover;
background-attachment: fixed;
display: flex;
flex-direction: column;
justify-content: center;
 align-items: center;
}
.work-main h3{
    background-color: whitesmoke;
    
}
.work-main p{
    background-color: whitesmoke;
}

.cont-h1{
    color: #6CAEB8; 
    cursor: pointer;
}

.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
}

.social-icon {
    font-size: 30px;
    margin: 0 15px;
    color: whitesmoke; /* Adjust the color as needed */
    transition: color 0.3s ease;
}

.social-icon:hover {
    color: #000000; /* Change color on hover */
}



@media only screen and (max-width: 800px) {
    
    .work-main h3 {
        font-size: x-large;
        
    }
    .work-main p{
        font-size: small;
    }


  }
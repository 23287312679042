

.carousel-heading{
    font-size: 3vw;
  }

  



@media only screen and (max-width: 800px) {
    
    .carousel-heading{
      
      font-size: 60%;
      font-weight: 900;
    }
    
    .carousel-paragraph{
        font-size: 40%;
    }
  }

.serv-main{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "DM Sans", sans-serif;
    background: #1e1f21;
    color: #fff;
}
/* .serv-body{
    background: #1e1f21;
    color: #fff;

} */
.pro-h2-body{
    /* padding-top: 80px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.serv-body h2{
    
    position: relative;
    font-size: 4vw;
    color: #1e1f21;
   -webkit-text-stroke: 0.1vw whitesmoke;
    text-transform: uppercase;
}
.serv-body h2 span{
    color: whitesmoke;
    
}
.serv-body h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: whitesmoke;
    -webkit-text-stroke: 0vw #242527;
    border-right: 2px solid whitesmoke;
    overflow: hidden;
    animation: animate 6s linear infinite;
}
@keyframes animate{
    0%,10%,100%
    {
        width: 0;
    }
    70%,90%
    {
        width: 100%;
    }
}
.wrapper{
    padding: 10px 10%;
}
.card-area{
    padding-bottom: 50px;
   

}
.box-area{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 40px;
    margin-top: 50px;
}
.box{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);


}
.box img {
    width: 100%;
    border-radius: 10px;
    display: block;
    transition: transform 0.5s;

}
.overlay{
    height: 0;
    width: 100%;
    background: linear-gradient(transparent, #1c1c1c 58%);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 80px;
    text-align: center;
    font-size: 14px;
    transition: height 0.5s;

}
.overlay h3{
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 80%;
    /* font-family: bebas neue; */
    font-family: "DM Sans", sans-serif;
    font-size: 30px;
    letter-spacing: 2px;
    color: #fff;

}
.overlay p{
    color: #fff;
    opacity: 75%;
}
.overlay a{
    margin-top: 10px;
    color: #262626;
    text-decoration: none;
    font-size: 14px;
    background: #fff;
    border-radius: 50px;
    text-align: center;
    padding: 5px 15px;

}
.box:hover img{
    transform: scale(1.2);


}
.box:hover .overlay{
    height: 100%;
}








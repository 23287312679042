/* Slick Carousel core styles */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* Additional styles for your component */
.featuredProject1-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 90%;
  /* max-width: 800px; */

  margin: auto;
  text-align: center;
}

.featuredProject1-carousel {
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  max-width: 800px; 
}

.featuredProject1-carousel-image {
  width: 100%;
  height: auto;
  max-width: 100%;
}

.featuredProject1-thumbnail-container {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  width: 95vw;
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Default 5 columns for larger screens */
}

.featuredProject1-thumbnail {
  position: relative;
  width: 100px;
  height: 80px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.featuredProject1-thumbnail-img {
  width: 100%;
  height: auto;
  display: block;
}

.featuredProject1-thumbnail.active {
  transform: scale(1.1);
}

.featuredProject1-thumbnail:hover .featuredProject1-overlay {
  opacity: 1;
  
}

.featuredProject1-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Responsive styles */
@media (max-width: 1400px) {
  .featuredProject1-overlay{
    height: 65%;
  }
 
}
@media (min-width: 1200px) {
  .featuredProject1-thumbnail-container {
    grid-template-columns: repeat(10, 1fr); /* 10 columns on very large screens */
  }
 
}

@media (min-width: 600px) and (max-width: 1199px) {
  .featuredProject1-thumbnail-container {
    grid-template-columns: repeat(5, 1fr); /* 5 columns on larger screens */
  }
  .featuredProject1-carousel {
    margin-bottom: 0px;
  }
  .featuredProject1-overlay{
    display: none;
  }
}

@media (max-width: 599px) {
  .featuredProject1-overlay{
    display: none;
  }
    .featuredProject1-thumbnail-container {
        margin-top: 0px;
        gap: 6px;
        width: 100vw;

      }
      .featuredProject1-carousel {
        height: 220px;
      }
  
}

/* @media (max-width: 400px) {
  .featuredProject1-thumbnail-container {
    grid-template-columns: 1fr; 
  }
} */
